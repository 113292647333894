html {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inter", -apple-system, Helvetica, Ubuntu, Roboto, "Segoe Ui",
    Arial, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: inherit;
  }
}

* {
  box-sizing: border-box;
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  #atlas-chat {
    display: none;
  }
}
